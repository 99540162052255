/* General styling for InventoryPage */
.inventory-page {
  display: flex;
  flex-direction: column;
  background-color: #303030;
  color: #ffffff;
  min-height: 100vh;
  width: 100%;
  height: 100%;
}

.content-container {
  display: flex;
  flex: 1;
  margin: 0;
  width: 100%;
  height: 100%;
}

.main-content {
  flex: 1;
  color: #ffffff;
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  height: 100px;
  background-color: #404040;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.header h2 {
  margin: 0;
}

.header button {
  padding: 10px 20px;
  background-color: #28a745;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Table Styling */
table {
  width: 100%;
  border: none;
}

th, td {
  padding: 10px;
  text-align: left;
  color: #ffffff;
}

.information-panel {
  width: 15%;
  background-color: #505050;
  color: #ffffff;
  padding: 20px;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
}

.information-panel h3 {
  margin-top: 0;
}

.information-panel button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #28a745;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.delete-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #ff0000;
  margin-left: 10px;
}

.withdraw-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #28a745;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

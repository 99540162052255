.items-list {
  background-color: #303030;
  color: #ffffff;
}

/* Featured Header */
.featured-header {
  font-weight: bold;
  margin-bottom: 20px;
}

/* Ensure item cards have space between them */
.items-container {
  padding-right:20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

/* Styling for empty state */
.empty-state {
  font-size: 1.2rem;
  text-align: center;
}

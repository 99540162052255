/* src/components/Banner.css */
.banner {
  background: linear-gradient(to right, #27ae60, #1abc9c); /* Green gradient background */
  color: white; /* Text color */
  padding: 40px 10px;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  height: 100%;
}

.banner h1 {
  font-size: 36px;
  margin: 0;
}

.banner p {
  font-size: 18px;
  margin: 10px 0 0;
}

.banner button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #fff; /* White button background */
  color: #27ae60; /* Green text */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.banner button:hover {
  background-color: #27ae60; /* Green on hover */
  color: white; /* Change text to white on hover */
}
  
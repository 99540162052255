/* ItemDetailPage.css */

/* Layout for the item header */
.item-header {
  max-height: 150px;
  width: 100%;
  display: flex; /* Make the header items appear in a row */
  flex-direction: row; /* Ensure the header uses a row layout */
  flex-wrap: nowrap; /* Prevent wrapping */
  justify-content: flex-start; /* Align them to the left */
  gap: 15px; /* Space between items */
}

/* Styling for the header text */
.item-header > div {
  font-size: 12px;
  color: #ffffff; /* White text */
}

/* Page container with flex layout */
.page-container {
  align-items: center;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  height: '100%';
  background-color: #303030;
  padding-right: 40px;
  max-width: 1200px;
}

/* Mid-row container */
.mid-row {
  display: flex;
  height: 500px;
  width: 100%; /* Full width of its container */
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

/* 70% width for the ItemChart */
.item-chart {
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  color: #ffffff;
  display: flex;
  /* You can add more styles as needed */
}

/* 30% width for the ItemMarketForm */
.item-market-form {
  flex: 0 0 30%; /* Takes 30% of the available space */
  background-color: #333;
  border-radius: 8px;
  color: #ffffff;
  display: flex;
  padding: 20px;
  /* You can add more styles as needed */
}



/* Styling for the left item details */
.item-left {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

/* Styling for item description */
.item-description {
  font-size: 14px;
  color: #555; /* Dark gray for description */
  margin-bottom: 10px; /* Space below description */
}

/* Styling for the order book section */
.item-order-book {
  max-height: 350px;
  max-width: 1200px;
  margin-bottom: 40px;
}

.order-book-description {
  font-size: 14px;
  color: #555;
  margin-bottom: 10px;
}

/* Active orders list styling */
.active-orders {
  width: 100%;
  padding-right: 20px;
  margin-bottom: 20px;
}

.active-orders ul {
  list-style-type: none;
  padding: 0;
}

.active-orders li {
  margin: 10px 0;
  cursor: pointer;
  color: #333;
}

/* Styling for the header text */
.item-header > div {
  font-size: 12px;
  color: #ffffff; /* White text */
}

/* Styling for the left item details */
.item-left {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

/* Styling for item description */
.item-description {
  font-size: 14px;
  color: #555; /* Dark gray for description */
  margin-bottom: 10px; /* Space below description */
}

.order-book-description {
  font-size: 14px;
  color: #555;
  margin-bottom: 10px;
}

/* Active orders list styling */
.active-orders {
  width: 100%;
  padding-right: 20px;
  margin-bottom: 20px;
}

.active-orders ul {
  list-style-type: none;
  padding: 0;
}

.active-orders li {
  margin: 10px 0;
  cursor: pointer;
  color: #333;
}

/* Responsive layout for smaller screens */
@media (max-width: 600px) {
  .page-container {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }

  .item-chart,
  .item-market-form {
    width: 30%;
  }

  .item-header {
    flex-direction: column; /* Stack header items vertically on smaller screens */
  }
}

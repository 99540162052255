/* General Container Styling */
.settings-page {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #303030;
  color: #ffffff;
}

.content-container {
  width: 100%;
  height: 100%;
}

/* Dark Paper Style for MUI Components */
.dark-paper {
  background-color: #303030 !important;
  color: #ffffff !important;
  height: 100%;
}

/* Hover effect for Paper */
.dark-paper:hover {
  background-color: #414141 !important;  /* Change hover color */
}

/* Flex Layout */
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Icon button */
.dark-icon-button {
  color: #ffffff;
  cursor: pointer;
}

/* Select Menu Styling */
.dark-select {
  background-color: #424242;
  color: #ffffff;
  width: 100%;
  border: 1px solid #ffffff;
}

.dark-menu-item {
  background-color: #424242;
  color: #ffffff;
}

.table {
  background-color: #424242;
  color: #ffffff;
}

.dark-menu-item:hover {
  background-color: #616161;
}

/* Checkbox Styling */
.dark-checkbox .MuiCheckbox-root {
  color: #ffffff;
}

/* Slider Styling */
.dark-slider {
  color: #ffffff;
}

/* Add Card, Table, and Button Styling */
.card {
  background-color: #393939 !important;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: none !important; /* Disables the shadow */
}

.card:hover {
  background-color: #5c5c5c;
}

.card .MuiButtonBase-root {
  color: #ffffff;
}

.card .MuiButton-root {
  background-color: #616161;
}

.card .MuiButton-root:hover {
  background-color: #777777;
}

/* Table Styling for Dark Mode */
.table-container {
  background-color: #424242;
  color: #ffffff;
}

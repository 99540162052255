.item-card-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color */
}

.cropped-image {
  width: 160px; /* Remaining 4/6 after cropping */
  height: 160px;
  overflow: hidden;
  position: relative;
}

.cropped-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center;
}

.item-card {
  width: 180px;
  height: 300px;
  padding: 20px;
  margin-bottom: 10px;
  background-color: #303030;
  border: 1px solid #505050;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.5s ease;
}

.item-card:hover {
  background-color: #404040; /* Darker background */
  transform: translateY(-5px); /* Slight lift on hover */
  transform: scale(1.1);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3); /* Add shadow */
  text-underline-position: below;
  text-decoration: underline;
}

.item-card:active {
  transform: translateY(0); /* Reset lift effect */
}

.item-card.highlight {
  background-color: #dddddd;
  transition: background-color 0.5s ease-in-out;
}

.item-icon {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.item-name {
  font-size: 18px;
  margin-top: 0px 0;
  max-width: 200px;
  max-height: 75px;
  color: #ffffff;
  flex: 1;
  white-space: normal;
  overflow: wrap;
  text-overflow: ellipsis;
  transition: all 0.2s ease;      /* Smooth transition for hover effects */

}
.item-name:hover {
  transform: scale(1.05);         /* Slightly larger on hover */
}

.item-card.highlight {
  background-color: #dddddd;
  transition: 0.5s ease-in-out;
}


.item-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.item-price {
  font-size: 18px;
  color: #ffffff;
  /* Add emphasis with one or more of these properties: */
  font-weight: 600;                /* Make text bolder */
  letter-spacing: 0.5px;          /* Spread letters slightly */
  text-shadow: 0 0 1px #b7b7b7;   /* Subtle glow effect */
  
  /* Optional: add hover effect */
  transition: all 0.2s ease;      /* Smooth transition for hover effects */
}
.item-price:hover {
  color: #ffffff;                 /* Brighter color on hover */
  transform: scale(1.05);         /* Slightly larger on hover */
}

.item-last-traded {
  font-size: 14px;
  color: #b7b7b7;
}

/* Container and General Table Styling */
.orders-table-container {
  background-color: #303030;
  padding: 20px;
  max-width: 1200px;
  width: 1100px;
}

.orders-table-container .orders-table-cell {
  color: #ffffff !important;
  text-align: left;
  padding: 10px !important;
  border: none;

}

/* Table Header Styling */
.orders-table-header {
  background-color: #424242 !important;
}

.orders-table-header-cell {
  color: #ffffff !important;
  font-weight: bold !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  border-bottom: 1px solid #555 !important;
}

/* Table Body Styling */
.orders-table-row {
  background-color: #303030 !important;
  color: #ffffff !important;
}

.orders-table-row:hover {
  background-color: #383838 !important;
}

/* Row Headers Styling */
.orders-table-row-header-cell {
  font-weight: bold !important;
  color: #ffffff !important;
  text-align: left !important;
  padding: 12px !important;
  background-color: #424242 !important;
  border-bottom: 2px solid #555 !important;
}

/* Table Cell Styling */
.orders-table-cell {
  color: #ffffff !important;
  text-align: left !important;
}

/* Order Type Badge Colors */
.order-buy {
  color: #4caf50 !important; /* Green for buy orders */
}

.order-sell {
  color: #f44336 !important; /* Red for sell orders */
}

/* Delete Button Icon */
.delete-icon {
  color: #f44336 !important;
}

/* src/components/FAQSection.css */
.faq-section {
  background-color: #f9f9f9; /* Light gray background */
  padding: 20px;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  margin: 20px 0; /* Margin above and below the section */
}

.faq-title {
  font-size: 24px;
  color: #27ae60; /* Green title color */
  margin-bottom: 15px; /* Space below the title */
}

.faq-item {
  margin-bottom: 15px; /* Space between FAQ items */
}

.faq-question {
  font-size: 18px;
  font-weight: bold;
  color: #333; /* Dark gray color for questions */
  cursor: pointer; /* Pointer cursor for interactive element */
  transition: color 0.3s ease; /* Transition for hover effect */
}

.faq-question:hover {
  color: #27ae60; /* Change color on hover */
}

.faq-answer {
  font-size: 16px;
  color: #555; /* Dark gray color for answers */
  margin-top: 5px; /* Space above the answer */
  display: none; /* Initially hide answers */
}

.faq-answer.active {
  display: block; /* Show answer when active */
}

/* index.css */

body {
  margin: 0;
  height: 100%;
  min-height: 100vh;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none;  /* Firefox */
}

/* Hide the scrollbar but allow scrolling */
body::-webkit-scrollbar {
  display: none;
}

/* Ensure that code elements still have their monospace font */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

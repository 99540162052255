.market-page {
  display: flex;
  flex-direction: column; /* Stack Homebar and content */
  background-color: #303030;
  height: 100%; /* Full viewport height */
  width: 100%;
  overflow-y: auto;
  min-height: 100vh; /* Minimum height of the viewport */
}

.market-content-container {
  display: flex;
  margin: 0; /* Remove all margins */
  width: 100%;
  height: 100%; /* Fill the parent container */
}


.market-main-content {
  display: flex;
  flex-direction: column; /* Stack TagInput and ItemsList */
  height: 100%;
  width: 85%;;
  flex-grow: 1; /* Fill remaining horizontal space */
  padding: 20px; /* Add padding to the content */
}

.tag-input {
  width: 100%; /* Make the TagInput stretch the full width */
  margin: 0; /* Remove any margin */
  padding: 0; /* Remove padding */
}


.items-list {
  flex-grow: 1; /* Let the items list grow to fill available space */
  overflow-y: auto; /* Scroll if necessary */
  margin: 0; /* Remove margin to avoid unnecessary space */
  padding: 0 20px; /* Padding to match layout */
}

.items-list h2 {
  font-size: 24px;
  color: #27ae60; /* Green title color */
  margin-bottom: 15px; /* Space below the title */
}

.item-description {
  font-size: 14px;
  color: #555; /* Dark gray for description */
  margin-bottom: 10px; /* Space below description */
}

.buy-button {
  padding: 10px 15px; /* Padding for the button */
  background-color: #27ae60; /* Green background */
  color: white; /* White text */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s ease; /* Transition for hover effect */
}

.buy-button:hover {
  background-color: #1abc9c; /* Lighter green on hover */
}


/* Overall container styling */
.filter-menu {
  background-color: #303030;
  color: #ffffff;
  padding: 20px;
  min-width: 125px;
  width: 100%;
}

/* Dark Paper Style */
.dark-paper {
  background-color: #363636 !important;
  color: #ffffff !important;
}

/* Hover effect on boxes */
.dark-paper:hover {
  background-color: #414141 !important;  /* Change to your desired hover color */
}


/* Flex container styling */
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Icon button styling */
.dark-icon-button {
  color: #ffffff;
  cursor: pointer;
}

/* Select Menu Styling */
.dark-select {
  background-color: #424242;
  color: #ffffff;
  width: 100%;
  border: 1px solid #ffffff;
}

.dark-select .MuiSelect-root {
  color: #ffffff;
}

.dark-menu-item {
  background-color: #424242;
  color: #ffffff;
}

.dark-menu-item:hover {
  background-color: #616161;
}

/* Checkbox Styling */
.dark-checkbox .MuiCheckbox-root {
  color: #ffffff;
}

/* Slider Styling */
.dark-slider {
  color: #ffffff;
}

.cursor-pointer {
  cursor: pointer;
}

.wallet-page {
  display: flex;
  flex-direction: column; /* Stack Homebar and content */
  background-color: #303030;
  height: 100vh; /* Full viewport height */
  width: 100%;
  overflow-y: auto;
  align-items: center;
}

.content-container {
  display: flex;
  margin: 0; /* Remove all margins */
  width: 100%;
  max-width: 1200px;
  flex-grow: 1; /* Allow content-container to grow */
}

.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1; /* Fill remaining horizontal space */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */

}

.wallet-transaction-list {
  flex-grow: 1; /* Let the transaction list grow to fill available space */
  overflow-y: auto; /* Scroll if necessary */
  padding: 20px; /* Padding for the transaction list */
}

